/**
 * Convert an address in our apps' standard format to ShipEngine
 * format. This function should mostly help with migrating API
 * routes that haven't been updated to use the new format.
 * @param {Object} address An address in our app's standard format
 */
export const addressToShipEngineFormat = (address) => ({
    'name': address.name || null,
    'phone': address.phone || null,
    'company_name': address.company_name || null,
    'address_line1': address.address_line_1,
    'address_line2': address.address_line_2 || null,
    'city_locality': address.city,
    'state_province': address.state_province_code,
    'postal_code': address.postal_code,
    'country_code': address.country_iso_code,
});
