const symbologyIdentifiers = [
    ']C1',
    ']e0',
    ']d2',
    ']Q3',
];

const fixedIdentifierLengths = {
    '00': 20,
    '01': 16,
    '02': 16,
    '03': 16,
    '04': 18,
    '11': 8,
    '12': 8,
    '13': 8,
    '14': 8,
    '15': 8,
    '16': 8,
    '17': 8,
    '18': 8,
    '19': 8,
    '20': 4,
    '31': 10,
    '32': 10,
    '33': 10,
    '34': 10,
    '35': 10,
    '36': 10,
    '41': 16,
};

const commonElements = {
    '01': 'GTIN',
    '10': 'Batch/lot number',
    '11': 'Production date',
    '15': 'Best before date',
    '17': 'Expiration date',
    '21': 'Serial number',
};

const dateElements = ['11', '15', '17'];

/**
 * @param {string} scanData - Value to parse
 * @param {Object} [symbols] - Constant symbols used by the parser
 * @param {string} [symbols.gs] - Group separator symbol (default: `'\x1D'`)
 * @param {string} [symbols.pre] - Prefix to trim (default `''`)
 * @param {string} [symbols.suf] - Suffix to trim (default `''`)
 */
const parseGS1 = (scanData, { gs = String.fromCharCode(29), pre = '', post = '' } = {}) => {
    if (!scanData.startsWith(pre) || !scanData.endsWith(post)) {
        throw TypeError('Input does not start and end with prefix/suffix');
    }
    scanData = scanData.slice(pre.length, scanData.length - post.length);

    // Values to mutate during parsing
    let data = scanData;
    const elements = {};

    const symbologyId = symbologyIdentifiers.find(symbol => data.startsWith(symbol));
    if (symbologyId === undefined) {
        throw TypeError('Not a GS1 symbology using GS1 AIs');
    }

    data = data.slice(3); // Remove first 3 characters
    if (data.length === 0) {
        throw TypeError('No data is present after symbology identifier');
    }

    // Keep parsing until we run out of characters
    while (data.length > 0) {
        const firstTwoChars = data.slice(0, 2);
        if (fixedIdentifierLengths[firstTwoChars] !== undefined) { // Fixed length
            const ai = firstTwoChars; // Application identifier
            const fixedLength = fixedIdentifierLengths[ai]; // Value includes the AI length

            if (data.length < fixedLength) {
                throw TypeError('Insufficient characters for AI with predefined length');
            }

            const buffer = data.slice(2, fixedLength);
            if (buffer.includes(gs)) {
                throw TypeError('Predefined-length element string contains unexpected <GS>');
            }

            data = data.slice(fixedLength);
            elements[ai] = buffer;
        } else { // Variable length
            const gsIndex = data.indexOf(gs);
            let buffer = (gsIndex === -1) ? data : data.slice(0, gsIndex);
            data = data.slice(buffer.length);

            const ai = Object.keys(commonElements).find(element => buffer.startsWith(element));
            if (ai === undefined) {
                throw TypeError('Unable to identify AI for variable-length element string');
            }
            buffer = buffer.slice(ai.length);

            elements[ai] = buffer;
        }

        // If there's still a trailing <GS>, remove it
        if (data.startsWith(gs)) {
            data = data.slice(gs.length);
        }
    }

    return {
        input: scanData, // Includes pre/suffix trimming
        symbologyIdentifier: symbologyId,
        elements,
    };
};

export { parseGS1, commonElements as gs1CommonElements, dateElements as gs1DateElements };
