import axios from 'axios';

export const downloadFile = async (downloadUrl, fileName) => {
    const { data: blob } = await axios.get(downloadUrl, { responseType: 'blob' });
    const blobURL = window.URL.createObjectURL(blob);

    try {
        // <a> tag trick lets us use the download attribute
        const aTag = document.createElement('a');
        aTag.href = blobURL;
        aTag.download = fileName;
        aTag.click();
    } finally {
        // Make sure we clean up our object URL
        window.URL.revokeObjectURL(blobURL);
        // The <a> tag will be cleaned up by the GC since we never insert it
    }
};
